import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    InputGroup, InputGroupAddon, InputGroupText,Table
  } from "reactstrap";
import dateTimeFormatter from "../../../utilities/DatetimeFormatter";

function PatientMessages({messages}){
    return (
        <>
        <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Patient Messages</h3>
            </Col>
            <Col className="text-right" xs="4">
              <Button
                color="primary"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="md"
              >
                Message
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
        <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                      <tr>
                    <th scope="col">Message</th>
                    <th scope="col">Send</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col" >Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                        {messages && messages.map((e)=>{
                            return (<tr>
                                <td>{e.message}</td>
                                <td>{dateTimeFormatter(e.created_at)}</td>
                                <td>
                                <Button
                                    color="primary"
                                    onClick={(e) => e.preventDefault()}
                                    size="md"
                                    >
                                    Reply
                                </Button>
                                </td>
                                <td>

                                <Button
                                    color="danger"
                                    onClick={(e) => e.preventDefault()}
                                    size="md"
                                    >
                                    Delete
                                </Button>
                                </td>
                            </tr>)
                        })}
                    </tbody>
        </Table>
        </CardBody>
        </Card>

        </>
    )
}

export default PatientMessages;