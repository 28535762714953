import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import useUser from "./hooks/user";
import Dashboard from "./Pages/Dashboard";
import PatientDetail from "./Pages/Patient/PatientDetail";
import LoginPage from "./Pages/Login";
import Demo from "./Pages/Test";
import 'react-notifications/lib/notifications.css';
import { CustomLoader } from "./components/Loader/circularLoader";
import { CustomAlert } from "./components/Alert";

const App = () => {
  const {isLogin,loader,customalert }= useUser();
  return (
    <>
    <CustomAlert open={customalert.Input.isOpen} message={customalert.Input.msg} type={customalert.Input.type} />
    <CustomLoader open={loader} />
    <Routes>
      {isLogin ? (
        <>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/Dashboard/Patient/:id" element={<PatientDetail />} />
          <Route exact path="/test" element={<Demo />} />
        </>
      ) : (
        <Route path="/" element={<Navigate to="/login" />} />
      )}
      {!isLogin ? (
        <Route exact path="/login" element={<LoginPage />} />
      ) : (
        <Route path="/login" element={<Navigate to="/" />} />
      )}
    </Routes>
    </>
  );
};

export default App;
