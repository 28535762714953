import {
  Input,
} from "reactstrap";

import { useEffect, useState } from "react"

export function FormTextField({id,value,placeholder,onChange,type}){

  let types ={
    number:/[0-9]/,
    alphas:/[a-zA-Z]/
  }
  const validateInput = (event)=>{
    console.log(event);
    if (!types[type].test(event.key))
    event.preventDefault();
  }
  useEffect(()=>{
    
  },[]);
    return(
        <>
          <Input id={id} 
          onKeyDown={(event)=>validateInput(event)}
          onChange={(event)=>{onChange(event)}} 
          placeholder={placeholder}
          value={value}/>
        </>
    )
}