export default function dateTimeFormatter(date){
    var _date = new Date(date);
   var formatter =  new Intl.DateTimeFormat('default', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      })
      
      return formatter.format(_date);
}
