export const ApiURL={
    patient:{
        get:{
            patientdetial:'patient/patient/singlepatient/'
        },
        patch:{
            patientdetial:'patient/patient/singlepatient/'
        },
        post:{

        }
    },
    dashboard:{
        get:{
            allpatients:'patient/patient/allpatient/'
        }
    }
}