import { Backdrop, CircularProgress } from "@mui/material"

export const CustomLoader=({open})=>{

    return(
    <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={open}
>
  <CircularProgress color="primary" />
</Backdrop>);
}