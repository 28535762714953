import { AlertTitle } from "@mui/material";
import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";

import Loader from "../components/Loader";
import { __API_URL__ } from "../SERVER_URL";
import { AlertStatus } from "../servicemanager/enums";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const tokenSaved = localStorage["token"];
  const [isLogin, setIsLogin] = useState(
    tokenSaved === undefined ? false : true
  );
  const [token, setToken] = useState(
    localStorage.getItem("token") === undefined
      ? null
      : localStorage.getItem("token")
  );
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader,setLoader] = useState(false);
  const [alertOpen,setAlert] = useState({
    isOpen:false,
    type:AlertStatus.SUCCESS,
    msg:''
  });

  const logout = useCallback(() => {
    console.log("logout", localStorage.getItem("token"));
    setToken(null);
    setIsLogin(false);
    setUser(null);
    setLoading(false);


    localStorage.removeItem("token");
  }, []);

  const fetchUser = useCallback(
    (options) => {
      return new Promise((resolve, reject) => {
        const userURL = `${__API_URL__}/api/practitioners/personal-info/`;
        const userRequest = axios.get(userURL, options);

        const request = [userRequest];

        axios
          .all(request)
          .then(
            axios.spread((...responses) => {
              setUser(responses[0].data);
              setIsLogin(true);
              setLoading(false);
              resolve(123);
            })
          )
          .catch((err) => {
            console.log(err);
            if (axios.isAxiosError(err)) {
              logout();
              resolve(123);
            }
            reject(err);
          });
      });
    },
    [logout]
  );

  const login = useCallback(
    (ld) => {
      setLoading(true);
      return (
        axios.post(`${__API_URL__}/api/practitioners/login/`, ld)
          .then(async (res) => {
            setToken(res.data.token);
            localStorage.setItem("token", res.data.token);

            const options = {
              headers: { Authorization: `Token ${res.data.token}` },
            };

            await fetchUser(options);
            return;
          })
          .catch((err) => {
            setLoading(false);
            throw err;
          })
      );
    },
    [fetchUser]
  );

  useLayoutEffect(() => {
    if (
      localStorage["token"] !== undefined &&
      user === null
    ) {
      const options = {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      };
      const getUser = async () => {
        setLoading(true);
        await fetchUser(options);
        setLoading(false);
      };
      getUser();
    }
  }, [fetchUser, user]);

  return (
    <UserContext.Provider
      value={{
        isLogin,
        actions: {
          login,
          logout,
        },
        loading,
        loader,
        setLoading:setLoader,
        customalert:{
          Input:alertOpen,
          setAlert:setAlert
        },
        state: {
          user,
          token,
        },
      }}
    >
      {isLogin ? (
        token === null || user === null ? (
          <Loader />
        ) : (
          
          children
        )
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);
export default useUser;
