import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./hooks/user";
import {NotificationContainer} from 'react-notifications';
import { Box } from "@mui/material";
import Loader from "./components/Loader";
import { Utitlity } from "./hooks/utility";

const app = (
  <BrowserRouter>
    <UserProvider>
      <Utitlity>
        <App />
        <NotificationContainer />
      </Utitlity>
    </UserProvider>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
