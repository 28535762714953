import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";

import {useParams} from 'react-router-dom';
import { useState,useEffect } from "react";


import '../../assets/plugins/nucleo/css/nucleo.css'; //'../assets/plugins/nucleo/css/nucleo.css' '';
import '../../assets/css/argon-dashboard-react.css';
 import "@fortawesome/fontawesome-free/css/all.min.css";


import PersonalInfo from "../../components/Patient/PatientDetail/PersonalInfo";
import Appointments from "../../components/Patient/PatientDetail/Appointments";
import axios, { Axios } from "axios";
import useDocumentTitle from "../../hooks/documenttitile";
import PatientMessages from "../../components/Patient/PatientDetail/Messages";
import { ApiURL } from "../../servicemanager/apiurls";
import {createNotification} from '../../components/Notifications/Notificaiton'
import { useUtility } from "../../hooks/utility";
import { AlertStatus } from "../../servicemanager/enums";

function PatientDetail(){
  useDocumentTitle('Patient Detail');

  let { id } = useParams();

  let {ApiManager,AlertManager} = useUtility();

  let [skinTypes,setSkinTypes] = useState([]);

  let [selectedTab,setSelectedTab] = useState({
    current:"personalinfo",
    personalinfo:true,
    appointments:false,
    patientmessages:false,
  });
  let [patient,setPatient] = useState({
    id: id,
    first_name: "",
    last_name: "",
    gender: null,
    birth_date: null,
    email: "",
    phone: "",
    location: null,
    appiell_patient: [
      {
        skin_type: null,
        device: null
      }
    ],
    experience: [],
    Appointments: [],
    messages:[]
  })


  useEffect(() => {
      const urlapi =
        "https://staging.appiell.ai/api/patient/patient/singlepatient/?patientId="+id;
         ApiManager.get(urlapi).then((resp)=>{
          setPatient(resp);
        });
        const _skintypes = 'https://staging.appiell.ai/api/patient/patient/skintypeall/';
        ApiManager.get(_skintypes).then((skins)=>{
          setSkinTypes(skins);
        });
    
  }, []);

  const UpdatePatientDetail = (sender)=>{
    let inputname = sender.target.id;
    let inputvalue = sender.target.value;
    setPatient((prev)=>{
      return {
        ...prev,
        [inputname]:inputvalue
      }
    });
  }
  const UpdatePatientDetailSkinType = (skinvalue,skintypes)=>{

    let inputvalue = skinvalue.target.value;
    let inputpatient = typeof(patient.appiell_patient[0]) != 'undefined';
    let appiell_patientvalue = inputpatient && patient.appiell_patient[0];
    for (let item of skintypes){
      if (item.title == inputvalue)
      appiell_patientvalue.skin_type = item;
    }
    
    setPatient((prev)=>{
      return {
        ...prev,
        appiell_patient:[appiell_patientvalue]
      }
    });
  }
  useEffect(() => {
  }, []);

  const changeTab = (tabname)=>{
    let _currenttab = selectedTab.current;
    if (tabname != _currenttab){
      setSelectedTab(prev=> { return {...prev,
        'current':tabname,
        [tabname]:true,
        [_currenttab]:false
      }});
    }
  }

  const updatePatientInfo = ()=>{
    let _patient={
      firstName:patient.first_name,
      lastName:patient.last_name,
      phone:patient.phone,
      birthYear:'1800',
      gender:patient.gender,
      skinTypeId:patient.appiell_patient && patient.appiell_patient[0] && patient.appiell_patient[0].skin_type.id,
      email:patient.email
    }
    ApiManager.patch(ApiURL.patient.patch.patientdetial+'?patientId='+id,_patient)
    .then((response)=>{
      AlertManager('Patient Successfully Updated',AlertStatus.SUCCESS);
      
    }).catch((xhr)=>{
      AlertManager('Internal Server Error',AlertStatus.ERROR);
    })
  }

  return(
    <> 
    <Container className="mt-7" fluid>
    <Row>
    <Col lg="12" className="mb-3">
    <ul class="nav nav-pills nav-fill flex-column flex-sm-row" id="tabs-text" role="tablist">
                <li class="nav-item" onClick={()=>{changeTab('personalinfo')}}>
                    <a className={"nav-link mb-sm-3 mb-md-0 "+(selectedTab.personalinfo?"active":"")} id="tabs-text-1-tab" data-toggle="tab" role="tab" aria-controls="tabs-text-1" aria-selected="true">Personal
                        Info</a>
                </li>
                <li class="nav-item" onClick={()=>{changeTab('appointments')}}>
                    <a className={"nav-link mb-sm-3 mb-md-0 "+(selectedTab.appointments?"active":"")} id="tabs-text-2-tab" data-toggle="tab" role="tab" aria-controls="tabs-text-2" aria-selected="false">Appointments</a>
                </li>
                <li class="nav-item" onClick={()=>{changeTab('patientmessages')}} onclick="OpenPatientTab('message_card')">
                    <a className={"nav-link mb-sm-3 mb-md-0 "+(selectedTab.patientmessages?"active":"")} id="tabs-text-3-tab" data-toggle="tab" role="tab" aria-controls="tabs-text-3" aria-selected="false">Messages</a>
                </li>
                <li class="nav-item" onclick="OpenPatientTab('photo_card')">
                    <a class="nav-link mb-sm-3 mb-md-0 litabphoto" id="tabs-text-4-tab" data-toggle="tab" role="tab" aria-controls="tabs-text-3" aria-selected="false">Photos</a>
                </li>
                <li class="nav-item" onclick="OpenPatientTab('activity_Card');get_activity('/dashboard/patient_activity_log/','369');">
                    <a class="nav-link mb-sm-3 mb-md-0 litabactivity" id="tabs-text-6-tab" data-toggle="tab" role="tab" aria-controls="tabs-text-3" aria-selected="false">Activity</a>
                </li>
            </ul>
    </Col>
     <Col className="order-xl-1" xl="12">


       { (selectedTab.personalinfo && <PersonalInfo
        patient={patient}
         UpdatePatientDetailSkinType={UpdatePatientDetailSkinType} 
         UpdatePatientDetail={UpdatePatientDetail}
         UpdatePatientInfo={updatePatientInfo}
         skinTypes={skinTypes} />)
       || (selectedTab.appointments && <Appointments {...patient} />)
        || (selectedTab.patientmessages && <PatientMessages {...patient} />)
        }

    </Col>
  </Row>
</Container></>
  )
}

export default PatientDetail;