import React, { useEffect, useState, useMemo } from "react";
import { Container } from "reactstrap";
import TableContainer from '../../TableContainer'; //"../components/TableContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { SelectColumnFilter } from "../../filters";
import { Link } from "react-router-dom";
import dateTimeFormatter from "../../../utilities/DatetimeFormatter";





function Appointments({Appointments}){
 
// import useUser from "../hooks/user";
  const [data, setData] = useState([]);
  // const user = useUser();

  useEffect(() => {
    
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Clinic",
        disableFilters:true,
        accessor: "clinic.name",
      },
      {
        Header: "Appointment Time",
        disableFilters:true,
        accessor: "time",
        Cell:({value})=><>{dateTimeFormatter(value)}</>
      },
      {
        Header: "Duration",
        disableFilters:true,
        accessor: "duration",
      },
      {
        Header: "Status",
        disableFilters:true,
        accessor: "status",
      },
      {
        Header: "Notes",
        disableFilters:true,
        accessor: "notes",
      },
      {
        Header:"Created at",
        disableFilters:true,
        accessor:"created_at",
        Cell:({value})=><>{dateTimeFormatter(value)}</>
      }
    ],
    []
  );
  return (
      <>
      <TableContainer style={{ marginTop: 10}} columns={columns} data={Appointments} />
      </>
  );
}

export default Appointments;