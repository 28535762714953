import React, { useEffect, useState, useMemo } from "react";
import { Container } from "reactstrap";
import TableContainer from "../components/TableContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { SelectColumnFilter } from "../components/filters";
import { Link } from "react-router-dom";
import { useUtility } from "../hooks/utility";
// import useUser from "../hooks/user";

const Home = () => {
  const [data, setData] = useState([]);
  // const user = useUser();
  const {ApiManager} = useUtility();

  useEffect(() => {
    const doFetch = async () => {
      const urlapi =
        "https://staging.appiell.ai/api/patient/patient/allpatient/";
        ApiManager.get(urlapi).then((response)=>{
          let data = response;
          setData(data);
        })
    };
    doFetch();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Patient Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "display_name",
      },
      {
        Header: "Email",
        accessor: "email",
        id:"email",
        Cell:({row,value})=>{ return(<Link to={{pathname:"/Dashboard/Patient/"+row.original.id}} >{value}</Link>)}
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Clinic",
        accessor: "clinic.name",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header:"Unread Messages",
        disableFilters:true,
        accessor:"messages.length"
      },
      {
        Header: "Fired",
        disableFilters:true,
        accessor: (_data)=>_data.fired?'Yes':'No',
        id:({row})=> 'Fired-'+row.id
      },
      {
        Header: "VIP",
        disableFilters:true,
        accessor: (_data)=>_data.vip?'Yes':'No',
        id:({row})=> 'Vip-'+row.id
      }
    ],
    []
  );
  return (
    <Container style={{ marginTop: 10 }}>
      <h1>Appiell Patient Dashboard</h1>
      {/* <h1 onClick={() => {
        console.log("clicked");
        user.actions.logout();
      }}>Logout</h1> */}
    {data && <TableContainer style={{ marginTop: 50 }} columns={columns} data={data} />}
    </Container>
  );
};

export default Home;
