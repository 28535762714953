import { Snackbar,Alert } from "@mui/material";
import useUser from "../hooks/user"

export const CustomAlert=({open,type,message})=>{
    let {customalert } = useUser();
    const handleClose = ()=>{
        customalert.setAlert((prev)=>{return {...prev,isOpen:false}});
    }
    return(
    <Snackbar open={open} autoHideDuration={30000} onClose={handleClose}>
        <Alert severity={type} sx={{ width: '100%' }} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
    )
}