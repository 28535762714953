import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup, InputGroupAddon, InputGroupText,Table
} from "reactstrap";

import {useEffect, useState} from 'react'
import axios from "axios";
import { FormTextField } from "../../Form/TextField";
import { useUtility } from "../../../hooks/utility";




function PersonalInfo({patient
  ,UpdatePatientDetail
  ,UpdatePatientDetailSkinType
  ,UpdatePatientInfo
,skinTypes}){
let personalInfo = patient;


  return(
     
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">Patient Info</h3>
            </Col>
            <Col className="text-right" xs="4">
              <Button
                color="primary"
                href=""
                onClick={(e) => UpdatePatientInfo()}
                size="md"
              >
                Update
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form>
            <div className="pl-lg-2">
              <Row>
                <Col lg="6">
                <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      First Name
                    </label>
                    <InputGroup>
                      <InputGroupAddon className="" addonType="prepend">@</InputGroupAddon>
                      {/* <Input id="first_name" onChange={(event)=>{UpdatePatientDetail(event)}} placeholder="First Name" value={personalInfo.first_name}/> */}
                      <FormTextField type="alphas"
                       id="first_name" 
                       onChange={(event)=>{UpdatePatientDetail(event)}}
                        placeholder="First Name"
                         value={personalInfo.first_name} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Last Name
                    </label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">@</InputGroupAddon>
                      <Input
                      onChange={(event)=>{UpdatePatientDetail(event)}}
                       placeholder="Last Name" 
                       id="last_name" 
                       value={personalInfo.last_name} />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
              <Col lg="6">
                <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Email Address
                    </label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">@</InputGroupAddon>
                      <Input disabled={true} placeholder="Email Address" value={personalInfo.email} />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg="6">
                <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Phone Number
                    </label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">@</InputGroupAddon>
                      <Input placeholder="Phone Number"
                      onChange={(event)=>{UpdatePatientDetail(event)}}
                      id="phone"
                      value={personalInfo.phone} />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
              <Col lg="6">
                <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Gender
                    </label>
                    <select class="form-control shadow-none"
                     value={personalInfo.gender}
                     onChange={(event)=>{UpdatePatientDetail(event)}}
                     id="gender"
                     data-toggle="select" data-minimum-results-for-search="Infinity">
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </select>
                    {/* <InputGroup>
                      <InputGroupAddon addonType="prepend">@</InputGroupAddon>
                      <Input placeholder="Male" />
                    </InputGroup> */}
                  </FormGroup>
                </Col>
                <Col lg="6">
                <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Birth Year
                    </label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">@</InputGroupAddon>
                      <Input  value={personalInfo.birth_date}
                       placeholder="DD/MM/YYYY"
                       onChange={(event)=>{UpdatePatientDetail(event)}}
                       id="birth_date"
                       />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="pl-lg-2">
              <Row>
                <Col md="12">
                <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Device
                    </label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">@</InputGroupAddon>
                      <Input disabled={true} value={personalInfo.appiell_patient &&
                         personalInfo.appiell_patient[0].device} placeholder="Device" />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                     Skin Type
                    </label>
                    <InputGroup>
                      <select class="form-control shadow-none" 
                      value={personalInfo.appiell_patient &&
                        personalInfo.appiell_patient[0].skin_type
                        && personalInfo.appiell_patient[0].skin_type.title}
                        onChange={(event)=>{UpdatePatientDetailSkinType(event,skinTypes)}}
                       id="skin_type"
                        >
                       {skinTypes && skinTypes.map(e=>{
                         return (<option value={e.title}>{e.description}</option>)
                       })}
                      </select>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                     Exprience
                    </label>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                      <tr>
                    <th scope="col">Treatment Type</th>
                    <th scope="col">Drug</th>
                    <th scope="col">Area</th>
                    <th scope="col" />
                      </tr>
                    </thead>
                    {/* <tbody>
                      {
                        personalInfo.experience.map((e,i)=>{
                          return (<tr>
                            <td>{e.treatment.title}</td>
                            <td>{e.area.name}</td>
                            <td>{e.treatments}</td>
                          </tr>)
                        })
                      }
                    </tbody> */}
                    </Table>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </CardBody>
      </Card>
   
  )
}

export default PersonalInfo;